import {
  Avatar,
  Box,
  Button,
  CSSObject,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { getMeEndpointIdentifier } from 'apis/use-me-api';
import { useUnlinkApi } from 'apis/vendors/use-unlink-api';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import { useVendorName } from 'hooks/use-vendor-name';
import { onLogout } from 'utils/on-logout';

const UnlinkAccountButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, mutate: unlinkApi } = useUnlinkApi();
  const { VendorName } = useVendorName();

  const onConfirm = () => {
    unlinkApi(
      {},
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <>
      <Button colorScheme='gray' variant='outline' onClick={onOpen}>
        Unlink {VendorName} Account
      </Button>

      <Modal variant='secondary' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unlink {VendorName} account</ModalHeader>
          <ModalBody>
            Are you sure you want to unlink the account? The link with your Foodics account will be deleted, not get
            back!
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' variant='ghost' onClick={onClose} me='sm'>
              Cancel
            </Button>
            <Button colorScheme='red' variant='solid' onClick={onConfirm} isLoading={isLoading}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const LogoutButton = () => {
  return (
    <Button colorScheme='red' variant='solid' onClick={onLogout}>
      Logout
    </Button>
  );
};

export const ProfileButton = () => {
  const me = useGetQueryClientData(getMeEndpointIdentifier);

  const unsetStyling: CSSObject = {
    backgroundColor: 'unset',
  };

  return (
    <Menu>
      <MenuButton>
        <Avatar name={me?.name} bgColor='red.500' color='white' fontWeight='bold' />
      </MenuButton>

      <MenuList zIndex={11}>
        <MenuItem as='div' _hover={{ ...unsetStyling }} _focus={{ ...unsetStyling }}>
          <Flex align='center'>
            <Avatar name={me?.name} bgColor='red.500' color='white' fontWeight='bold' size='lg' me='md' />
            <Box>
              <Text fontWeight='bold' fontSize='lg' mb='2xs' textTransform='capitalize'>
                {me?.name}
              </Text>
              <Text color='gray.500' fontSize='md'>
                {me?.email}
              </Text>
            </Box>
          </Flex>
        </MenuItem>
        <MenuDivider />
        <MenuItem as='div' _hover={{ ...unsetStyling }} _focus={{ ...unsetStyling }}>
          <VStack spacing='sm' w='full' align='stretch'>
            <UnlinkAccountButton />
            <LogoutButton />
          </VStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
