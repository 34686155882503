import { Button, Flex, Heading, Text } from '@elkaso-app/web-design';
import React from 'react';
import { useNavigate } from 'react-router';
import { LocalStorageKeys, URL } from 'utils/constants';
import { onLogout } from 'utils/on-logout';

const AccessDenied: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    const isVendorDefined = localStorage.getItem(LocalStorageKeys.vendor);

    if (isVendorDefined) {
      navigate(URL.HOME);
    } else {
      onLogout();
    }
  };

  return (
    <Flex height='100vh' alignItems='center' justifyContent='center'>
      <Flex direction='column' textAlign='center'>
        <Heading size='4xl' mb={4}>
          Access Denied
        </Heading>
        <Text fontSize='xl' mb={8}>
          You do not have permission to view this page.
        </Text>
        <Button colorScheme='red' onClick={handleGoHome}>
          Go to Home
        </Button>
      </Flex>
    </Flex>
  );
};

export default AccessDenied;
