/**
 * This function job is to return one and single valid value of an array of valid and invalid values.
 * We can send a lot of values for this function but all of these values should have only one valid value and the rest of the values should be null or undefined.
 * @param values this is an array of values
 * @returns single valid value with any valid type
 */
export const getValidValue = (values: any[]) => {
  const validValues: any[] = [];

  values.map((value: any) => {
    if (value) {
      validValues.push(value);
    }
  });

  if (validValues.length > 1) {
    console.warn('You have more than one valid value, remember we always return the first valid value', validValues);
  }

  return validValues?.[0] || '';
};
