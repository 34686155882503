import { useCustomToast } from '@elkaso-app/web-design';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import http from 'apis/config/vendor-http-service';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetKasoWarehousesApi } from './use-get-kaso-warehouses-api';

const endpoint = 'map-warehouse';
export const updateMapBranchEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

export type TVariables = {
  branchId: string;
  body: {
    vendorBranchId: string;
  };
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useUpdateMapWarehouseApi(options?: Options): ReturnType {
  const { refetch: refetchKasoWarehouses } = useGetKasoWarehousesApi({ enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ branchId, body }: TVariables) => {
    try {
      const { data } = await http.patch(endpoint + `/${branchId}`, body);

      // re-fetch warehouses list
      refetchKasoWarehouses();

      toast({
        description: 'Warehouse have been mapped successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
