import { Box, Button, Heading, HStack, PinInput, PinInputField, Text } from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSendOTPApi } from 'apis/auth/use-send-otp-api';
import { useValidateOTPApi } from 'apis/auth/use-validate-otp-api';
import { usePageParams } from 'hooks/use-page-params';
import { Layout, Section } from 'layout';
import { createRef, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { SessionStorageKeys, URL } from 'utils/constants';
import { handleRetryAttempts } from 'utils/handle-retry-attempts';
import { hashPortionOfString } from 'utils/hash-portion-of-string';
import { SessionHandler } from 'utils/session-handler';
import * as yup from 'yup';
import { ResendCodeButton } from './components/resend-code-button';

type FormValues = {
  otp: string;
};

const schema = yup.object().shape({
  otp: yup.string().length(4, 'OTP code must be exactly 4 digits.').required('OTP code is required.'),
});

const defaultValues: FormValues = {
  otp: '',
};

const getLoginBody = (loginType: string, loginValue: string) => {
  if (loginType === 'phoneNumber' || loginType === 'email') return { [loginType]: loginValue };
  return {};
};

const VerifyOtpPage = () => {
  const submitButton: React.LegacyRef<HTMLButtonElement> | undefined = createRef();
  const sessionHandler = new SessionHandler(SessionStorageKeys.otp_expiration_time);
  const navigate = useNavigate();

  const { isLoading: isLoadingSendOTP, mutate: sendOTP } = useSendOTPApi();
  const { isLoading: isLoadingValidateOTP, mutateAsync: validateOTP } = useValidateOTPApi();

  const { getPageParams } = usePageParams();
  const { loginType, loginValue } = getPageParams({ parseNumbers: false, decode: false });

  const isEmail = useMemo(() => {
    return loginType === 'email';
  }, [loginType]);

  const { increaseRetryAttempts, resetRetryAttempts } = handleRetryAttempts();

  const onSendOTP = () => {
    const otpBody = getLoginBody(loginType as string, loginValue as string);
    const variables = { body: otpBody };

    sendOTP(variables, {
      onSuccess(data) {
        sessionHandler.setSession(data?.secondsRemaining);

        increaseRetryAttempts();
      },
      onError(error) {
        // CASE [1]: Trying to send another OTP while the remaining time still counting.
        if (error.response?.status === 403) {
          sessionHandler.setSession(error?.response?.data?.secondsRemaining);
          return;
        }

        // CASE [2]: Locked for one hour due to many attempts incorrect OTPs.
        if (error.response?.status === 429) {
          sessionHandler.setSession(error?.response?.data?.secondsRemaining);
          navigate(URL.LOGIN + `?loginType=${loginType}&loginValue=${loginValue}`);
          return;
        }

        // CASE [4]: Any other error.
        if (error.response) {
          sessionHandler.setSession(error?.response?.data?.secondsRemaining);
          navigate(URL.LOGIN + `?loginType=${loginType}&loginValue=${loginValue}`);
          return;
        }
      },
    });
  };

  const { handleSubmit, control, reset } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const otpVerifyBody = { otp: data.otp, ...getLoginBody(loginType as string, loginValue as string) };

    validateOTP(
      { body: otpVerifyBody },
      {
        onSuccess: () => {
          resetRetryAttempts();
        },
        onError: () => {
          reset(defaultValues);
        },
      }
    );
  };

  // Call submit verification code Fn
  const onComplete = () => {
    submitButton.current?.click();
  };

  const getTitleLoginType = () => {
    if (isEmail) return 'email address';
    else return 'phone number';
  };

  const getSubtitleLoginType = () => {
    if (isEmail) return 'email';
    else return 'SMS';
  };

  if (!loginType || !loginValue) {
    return <Navigate to={URL.LOGIN} />;
  }

  return (
    <Layout type='pre_login_page_layout'>
      <Section name='content'>
        <Box w='100%' maxW='500px'>
          <Heading as='h3' mb='sm' color='blue.500'>
            Verify your {getTitleLoginType()}
          </Heading>

          <Text fontSize='md' color='gray.500' mt='sm'>
            An {getSubtitleLoginType()} verification OTP has been sent to
          </Text>

          <Text color='blue.500' fontWeight='bold' fontSize='lg' mt='lg'>
            {hashPortionOfString(loginValue.toString(), 'center', 0.4)}
          </Text>

          <Box mt='lg'>
            <form id='verificationCodeForm' onSubmit={handleSubmit(onSubmit)} noValidate className='mt-8'>
              <Controller
                control={control}
                name='otp'
                render={({ field: { onChange, value } }) => (
                  <HStack spacing='md'>
                    <PinInput
                      placeholder=''
                      size='lg'
                      variant='flushed'
                      colorScheme='red'
                      value={value}
                      onChange={onChange}
                      onComplete={onComplete}
                      isDisabled={isLoadingSendOTP || isLoadingValidateOTP}
                      autoFocus>
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                )}
              />
            </form>
          </Box>

          <ResendCodeButton
            onResend={onSendOTP}
            isLoading={isLoadingSendOTP}
            secondsRemaining={sessionHandler.remainingTime()}
            mt='md'
          />

          <Button type='submit' form='verificationCodeForm' ref={submitButton} display='none' />
        </Box>
      </Section>
    </Layout>
  );
};

export default VerifyOtpPage;
