export function hashPortionOfString(
  inputString: string,
  hashPlacement: 'start' | 'center' | 'end',
  hashRatio: number
): string {
  // Ensure hashRatio is within the expected range [0, 1]
  const validHashRatio = Math.min(Math.max(hashRatio, 0), 1);

  // Calculate the total number of characters to hash
  const charsToHash = Math.floor(inputString.length * validHashRatio);
  let hashedString = inputString;

  if (charsToHash > 0) {
    switch (hashPlacement) {
      case 'start':
        hashedString = '*'.repeat(charsToHash) + inputString.slice(charsToHash);
        break;
      case 'center': {
        const start = Math.floor((inputString.length - charsToHash) / 2);
        hashedString = inputString.slice(0, start) + '*'.repeat(charsToHash) + inputString.slice(start + charsToHash);
        break;
      }
      case 'end':
        hashedString = inputString.slice(0, inputString.length - charsToHash) + '*'.repeat(charsToHash);
        break;
    }
  }

  return hashedString;
}
