import { BrandLogo, Flex, Stack } from '@elkaso-app/web-design';
import { SidebarItem } from 'layout/components/sidebar/sidebar-item';
import { sidebarItems } from 'layout/components/sidebar/utils/sidebar-items';

export const Sidebar = () => {
  return (
    <Flex direction='column' h='full' pb='lg'>
      <Flex align={'center'} justify='center' h='68px' borderBottom={'2px solid'} borderColor='gray.200'>
        <BrandLogo />
      </Flex>

      <Stack spacing='xs' mt='md' flex={1}>
        {sidebarItems.map((item, index) => (
          <SidebarItem item={item} key={index} />
        ))}
      </Stack>
    </Flex>
  );
};
