import { FullPageSpinner } from '@elkaso-app/web-design';
import { useMeApi } from 'apis/use-me-api';
import { PreLoginRoute } from 'components/pre-login-route';
import { ProtectedRoute } from 'components/protected-route';
import { useRouteRedirectCorrection } from 'hooks/use-route-redirect-correction';
import { useSetAccessTokenFromSearchParams } from 'hooks/use-set-access-token-from-search-params';
import { useSetLangHtmlTags } from 'hooks/use-set-lang-html-tags';
import { useSyncLogout } from 'hooks/use-sync-logout';
import BranchesPage from 'pages/branches';
import ConfirmAccountPage from 'pages/confirm-account';
import NotFound from 'pages/general/404';
import AccessDenied from 'pages/general/access-denied';
import SessionExpired from 'pages/general/session-expired';
import ItemsPage from 'pages/items';
import LoginPage from 'pages/login';
import NotificationsPage from 'pages/notifications';
import SelectBrandsPage from 'pages/select-brands';
import SelectPOSPage from 'pages/select-pos';
import SuppliersPage from 'pages/suppliers';
import VerifyOtpPage from 'pages/verify-otp';
import 'react-phone-number-input/style.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import WarehouseItemsPage from './pages/warehouse-items';
import WarehousesPage from './pages/warehouses';

const HomePage = () => {
  // This is very important so we can force user got to the correct route.
  useRouteRedirectCorrection();
  return <FullPageSpinner />;
};

function App() {
  useSyncLogout();
  useSetLangHtmlTags();
  const { isLoading: isLoadingSetAccessToken } = useSetAccessTokenFromSearchParams();
  const { isLoading } = useMeApi();

  if (isLoading || isLoadingSetAccessToken) {
    return <FullPageSpinner />;
  }

  return (
    <Routes>
      <Route path='/'>
        {/* DEFAULT ROUTE */}
        <Route index element={<HomePage />} />

        {/* PRE LOGIN */}
        <Route path='/login' element={<PreLoginRoute component={<LoginPage />} />} />
        <Route path='/verify-otp' element={<PreLoginRoute component={<VerifyOtpPage />} />} />
        <Route path='/select-pos' element={<ProtectedRoute component={<SelectPOSPage />} />} />
        <Route path='/select-brands' element={<ProtectedRoute component={<SelectBrandsPage />} />} />
        <Route path='/confirm-account' element={<ProtectedRoute component={<ConfirmAccountPage />} />} />

        {/* POST LOGIN */}
        <Route path='app'>
          <Route index element={<Navigate to='branches' />} />
          <Route path='branches' element={<ProtectedRoute component={<BranchesPage />} />} />
          <Route path='warehouses' element={<ProtectedRoute component={<WarehousesPage />} />} />
          <Route path='suppliers' element={<ProtectedRoute component={<SuppliersPage />} />} />
          <Route path='items' element={<ProtectedRoute component={<ItemsPage />} />} />
          <Route path='warehouse_items' element={<ProtectedRoute component={<WarehouseItemsPage />} />} />
          <Route path='notifications' element={<ProtectedRoute component={<NotificationsPage />} />} />
        </Route>

        {/* GENERAL */}
        <Route path='/session-expired' element={<SessionExpired />} />
        <Route path='/access-denied' element={<AccessDenied />} />
        <Route path='/404' element={<NotFound />} />
        <Route path='*' element={<Navigate to='/404' />} />
      </Route>
    </Routes>
  );
}

export default App;
