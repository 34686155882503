import { Button, HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { TVariables as MapItemVariables, useMapItemApi } from 'apis/warehouse-items/use-map-warehouse-item-api';
import {
  TVariables as UnMapItemVariables,
  useUnmapWarehouseItemApi,
} from 'apis/warehouse-items/use-unmap-warehouse-item-api';
import {
  TVariables as UpdateMapItemVariables,
  useUpdateMapWarehouseItemApi,
} from 'apis/warehouse-items/use-update-map-warehouse-item-api';
import { usePageParams } from 'hooks/use-page-params';
import { MapIcon } from 'icons/map-icon';
import { UnMapIcon } from 'icons/unmap-icon';
import { IWarehouseItem } from '../index';

export interface IWarehouseItemsActionsCell {
  row: Record<string, any>;
  item: IWarehouseItem;
}

export const ActionsCell = ({ row, item }: IWarehouseItemsActionsCell) => {
  const { isLoading: isMapItemLoading, mutate: mapItemApi } = useMapItemApi();
  const { isLoading: isUpdateMapItemLoading, mutate: updateMapItemApi } = useUpdateMapWarehouseItemApi();
  const { isLoading: isUnMapItemLoading, mutate: unMapItemApi } = useUnmapWarehouseItemApi();
  const { getPageParams } = usePageParams();
  const params = getPageParams();

  const handleMap = () => {
    // Map for first time
    if (!item.posItemId) {
      const variables: MapItemVariables = {
        body: {
          itemId: row.state.itemId,
          vendorItemId: row.state.vendorItemId,
        },
      };

      if (params?.subEntityId) {
        variables.body.subEntityId = params.subEntityId as string;
        variables.body.subEntityType = params.subEntityType as string;
      }

      mapItemApi(variables);
    }

    // Update map if mapped before
    if (item.posItemId) {
      const variables: UpdateMapItemVariables = {
        itemId: row.state.itemId,
        body: {
          vendorItemId: row.state.vendorItemId,
        },
      };

      if (params?.subEntityId) {
        variables.body.subEntityId = params.subEntityId as string;
        variables.body.subEntityType = params.subEntityType as string;
      }

      updateMapItemApi(variables);
    }
  };

  const handleUnMap = () => {
    const variables: UnMapItemVariables = {
      itemId: item.kasoItemId,
      subEntityId: item.kasoSubEntityId,
      subEntityType: item.kasoSubEntityType,
    };

    unMapItemApi(variables);
  };

  return (
    <HStack spacing='xs'>
      <Button
        rightIcon={<MapIcon />}
        colorScheme='red'
        variant='solid'
        size='sm'
        px='md'
        isDisabled={!row.state.itemId}
        isLoading={isMapItemLoading || isUpdateMapItemLoading}
        onClick={handleMap}>
        Map
      </Button>

      <IconButtonWithTooltip
        label='Un-map'
        aria-label='Un-map Icon'
        colorScheme='blue'
        Icon={<UnMapIcon />}
        size='sm'
        isDisabled={!item.posItemId}
        isLoading={isUnMapItemLoading}
        onClick={handleUnMap}
      />
    </HStack>
  );
};
