import { useCustomToast } from '@elkaso-app/web-design';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import http from 'apis/config/vendor-http-service';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetKasoWarehouseItemsApi } from './use-get-kaso-warehouse-items-api';

const endpoint = 'items';
export const mapItemEndpointIdentifier = endpoint;

interface BodyType {
  itemId: string;
  vendorItemId: string;
  subEntityId?: string;
  subEntityType?: string;
}

type TData = Record<string, any>;
type TError = AxiosError;

export type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useMapItemApi(options?: Options): ReturnType {
  const { refetch: refetchKasoWarehouseItems } = useGetKasoWarehouseItemsApi({ enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { itemId, ...payload } = body;
      const { data } = await http.post(`${endpoint}/${itemId}/map`, payload, {
        baseURL: `${process.env.REACT_APP_BASE_URL}/pos/v2`,
      });

      // re-fetch warehouse items list
      refetchKasoWarehouseItems();

      toast({
        description: 'Item have been mapped successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
