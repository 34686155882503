import { Box, ChakraAsyncSelect, FormControl } from '@elkaso-app/web-design';
import http from 'apis/config/http-service';
import _debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type TOption = {
  value: string;
  label: string;
};

type FormInputs = {
  warehouse: TOption | undefined;
};

const defaultValues: FormInputs = {
  warehouse: undefined,
};

export interface ISelectAsyncWarehouseCell {
  row: Record<string, any>;
  id: number;
  defaultValue: { id: string; name: string };
}

export const SelectAsyncWarehouseCell = ({ row, id, defaultValue }: ISelectAsyncWarehouseCell) => {
  const [hasDefaultValue, setHasDefaultValue] = useState(false);

  const { control, setValue } = useForm<FormInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (!hasDefaultValue && defaultValue?.id && defaultValue?.name) {
      setValue('warehouse', { label: defaultValue.name, value: defaultValue.id });
      setHasDefaultValue(true);
    }
  }, [hasDefaultValue, defaultValue]);

  const handleRowStateUpdate = (selected: any) => {
    // When cleared
    if (!selected) {
      row.setState({});
    }

    // When selected
    if (selected) {
      row.setState({ warehouseId: id, vendorBranchId: selected.value });
    }
  };

  const debouncedLoadOptions = _debounce((inputValue, callback) => {
    http
      .get('pos/v2/foodics/warehouses', {
        params: { name: inputValue, page: 1, limit: 50 },
      })
      .then(({ data }) => {
        const values: TOption[] = data?.data?.map((item: any) => {
          return { value: item?.id, label: item?.name };
        });

        callback(values);
      });
  }, 500);

  return (
    <Box>
      <Controller
        control={control}
        name='warehouse'
        render={({ field: { onChange, name, onBlur, value } }) => (
          <FormControl>
            <ChakraAsyncSelect
              id='warehouse'
              onChange={(newValue) => {
                handleRowStateUpdate(newValue);
                onChange(newValue);
              }}
              name={name}
              onBlur={onBlur}
              value={value}
              isClearable
              placeholder='Type warehouse name'
              loadOptions={debouncedLoadOptions}
            />
          </FormControl>
        )}
      />
    </Box>
  );
};
