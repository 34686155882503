import { useCustomToast } from '@elkaso-app/web-design';
import auth from 'apis/config/auth-service';
import http, { getAccessToken } from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import vendorHttp from 'apis/config/vendor-http-service';
import type { AxiosError } from 'axios';
import { useRouteRedirectCorrection } from 'hooks/use-route-redirect-correction';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { LocalStorageKeys } from 'utils/constants';

const endpoint = 'ums/v1/auth/validate-otp';
export const validateOTPEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type BodyType = {
  otp: string;
  email?: string;
  phoneNumber?: string;
};

export type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useValidateOTPApi(options?: Options): ReturnType {
  const toast = useCustomToast();
  const { routeRedirectCorrection } = useRouteRedirectCorrection({ onInitiateCall: false });

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await auth.post(endpoint, body);

      // [1] - Add access token to local storage
      localStorage.setItem(LocalStorageKeys.access_token, data.accessToken);

      // [2] - Update default configs for axios instance
      http.defaults.headers.common['Authorization'] = getAccessToken();
      vendorHttp.defaults.headers.common['Authorization'] = getAccessToken();

      // [3] - Redirect user to the correct next step
      routeRedirectCorrection();

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
