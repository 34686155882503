import { Box, Flex, HStack, Icon, IconProps, Text, useBoolean, VStack } from '@elkaso-app/web-design';
import { ArrowIcon } from 'layout/components/sidebar/icons/arrow-icon';
import { DotIcon } from 'layout/components/sidebar/icons/dot-icon';
import { SidebarItemType } from 'layout/components/sidebar/utils/sidebar-items';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

interface IItemIcon extends IconProps {
  icon: typeof Icon;
}

const ItemIcon = ({ icon: Icon, ...rest }: IItemIcon) => {
  return <Icon {...rest} />;
};

interface ISidebarItem {
  item: SidebarItemType;
}

export const SidebarItem = ({ item }: ISidebarItem) => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useBoolean(false);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname.startsWith(item.path)) {
      return setIsActive(true);
    }

    setIsActive(false);
  }, [location.pathname]);

  useEffect(() => {
    if (isActive) return setIsOpen.on();

    () => {
      setIsOpen.off();
    };
  }, [isActive]);

  const handleIsOpen = () => {
    setIsOpen.toggle();
  };

  const isChildActive = (path: string) => {
    if (location.pathname.startsWith(path)) return true;
    return false;
  };

  // Check if item is disabled
  if (item.disable) return null;

  // Sidebar item with children
  if (item.children) {
    return (
      <Box>
        <Flex
          p={'sm'}
          align={'center'}
          bgColor={isOpen && !isActive ? 'gray.100' : isActive ? 'gray.100' : 'white'}
          cursor='pointer'
          userSelect={'none'}
          borderLeftWidth='3px'
          borderLeftColor={isActive ? 'red.500' : 'white'}
          _hover={{ bgColor: isActive ? 'gray.100' : 'gray.200' }}
          _active={{ bgColor: isActive ? 'gray.100' : 'gray.300' }}
          onClick={handleIsOpen}>
          <ItemIcon icon={item.icon} marginEnd={'md'} fill={isActive ? 'red.500' : 'gray.500'} />

          <Text color={isActive ? 'red.500' : 'gray.500'} fontSize='md' fontWeight='medium'>
            {t(item.name)}
          </Text>

          <ArrowIcon
            ms='auto'
            transform={isOpen ? 'rotate(0deg)' : 'rotate(180deg)'}
            fill={isActive ? 'red.500' : 'gray.500'}
          />
        </Flex>

        {isOpen && (
          <VStack spacing='sm' align='start' p='sm' ps='24px' bgColor='gray.50'>
            {item.children.map((item) => {
              if (item.disable) return null;

              return (
                <NavLink key={item.path} to={item.path} style={{ pointerEvents: isActive ? 'none' : 'initial' }}>
                  <HStack spacing='md'>
                    <DotIcon fill={isChildActive(item.path) ? 'red.500' : 'gray.500'} />
                    <Text color={isChildActive(item.path) ? 'red.500' : 'gray.600'}>{item.name}</Text>
                  </HStack>
                </NavLink>
              );
            })}
          </VStack>
        )}
      </Box>
    );
  }

  // Sidebar item with no children
  return (
    <NavLink to={item.path} style={{ pointerEvents: isActive ? 'none' : 'initial' }}>
      <Flex
        p={'sm'}
        align={'center'}
        borderLeftWidth='3px'
        borderLeftColor={isActive ? 'red.500' : 'white'}
        userSelect='none'
        bgColor={isActive ? 'gray.100' : 'white'}
        _hover={{ bgColor: 'gray.100' }}
        _active={{ bgColor: isActive ? 'gray.100' : 'gray.200' }}>
        <ItemIcon icon={item.icon} marginEnd={'md'} fill={isActive ? 'red.500' : 'gray.500'} />
        <Text
          color={isActive ? 'red.500' : 'gray.500'}
          fontSize='md'
          fontWeight='medium'
          cursor={isActive ? 'default' : 'pointer'}>
          {t(item.name)}
        </Text>
      </Flex>
    </NavLink>
  );
};
