import { ChakraSelect, FormControl } from '@elkaso-app/web-design';
import { useGetAllKasoBranchesApi } from 'apis/branches/use-get-all-kaso-branches-api';
import { usePageParams } from 'hooks/use-page-params';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type TOption = {
  value: string;
  label: string;
  type: string;
};

type TInputs = {
  options: TOption[] | undefined;
};

const defaultValues: TInputs = {
  options: undefined,
};

export const SelectKasoBranch = () => {
  const [options, setOptions] = useState<TOption[]>([]);
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  const { isLoading: isAllKasoBranchesLoading, data: allKasoBranchesData } = useGetAllKasoBranchesApi();

  useEffect(() => {
    if (isAllKasoBranchesLoading) return;

    const _options: TOption[] = [];

    allKasoBranchesData?.data?.map((subEntity: any) => {
      _options.push({ value: subEntity?.id, label: subEntity?.name, type: subEntity?.type });
    });

    setOptions(_options);
  }, [allKasoBranchesData]);

  const { control, watch, setValue } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  // Watch select input and update value
  useEffect(() => {
    const subscription = watch((value: any) => {
      const params = getPageParams();

      // When cleared
      if (!value?.options) {
        delete params.subEntityId;
        delete params.subEntityType;
        setPageParams(params);
      }

      // When selected
      if (value?.options && value?.options?.value) {
        params.subEntityId = value?.options?.value;
        params.subEntityType = value?.options?.type;
        setPageParams(params);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useMemo(() => {
    const subEntity = options.filter(
      (option) => option.value === params.subEntityId && option.type === params.subEntityType
    );

    return setValue('options', subEntity);
  }, [options]);

  return (
    <Suspense>
      <Controller
        control={control}
        name='options'
        render={({ field: { onChange, onBlur, value, name } }) => (
          <FormControl variant='outline' width='230px'>
            <ChakraSelect
              options={options}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={name}
              placeholder='All locations...'
              isLoading={isAllKasoBranchesLoading}
              isClearable
            />
          </FormControl>
        )}
      />
    </Suspense>
  );
};
