import { Button, Flex, Heading, Text } from '@elkaso-app/web-design';
import React from 'react';
import { onLogout } from 'utils/on-logout';

const SessionExpired: React.FC = () => {
  return (
    <Flex height='100vh' alignItems='center' justifyContent='center'>
      <Flex direction='column' textAlign='center'>
        <Heading size='4xl' mb={4}>
          Session Expired
        </Heading>
        <Text fontSize='xl' mb={8}>
          Your session has expired. Please log in again to continue.
        </Text>
        <Button colorScheme='red' onClick={onLogout}>
          Log In
        </Button>
      </Flex>
    </Flex>
  );
};

export default SessionExpired;
