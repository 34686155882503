import { Button, ButtonProps } from '@elkaso-app/web-design';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useEffect, useRef, useState } from 'react';
dayjs.extend(duration);

function formatSeconds(seconds: number): string {
  // Create a duration using the seconds provided.
  const dur = dayjs.duration(seconds, 'seconds');

  // Format the duration as 'mm:ss'.
  return `${String(dur.minutes()).padStart(2, '0')}:${String(dur.seconds()).padStart(2, '0')}`;
}

interface ResendCodeButtonProps extends ButtonProps {
  onResend: () => void;
  isLoading?: boolean;
  secondsRemaining?: number;
}

export const ResendCodeButton = ({ onResend, isLoading, secondsRemaining = 59, ...rest }: ResendCodeButtonProps) => {
  const [remainingTime, setRemainingTime] = useState(secondsRemaining);
  const remainingTimeRef = useRef(remainingTime);
  const [startTimer, setStartTimer] = useState(true);

  const syncRemainingTime = () => {
    setRemainingTime(remainingTimeRef.current);
  };

  useEffect(() => {
    remainingTimeRef.current = secondsRemaining;
    syncRemainingTime();
  }, [secondsRemaining]);

  useEffect(() => {
    if (!startTimer) return;

    const timerId = setInterval(() => {
      if (remainingTimeRef.current <= 0) {
        clearInterval(timerId);
        setStartTimer(false);
        return;
      }
      remainingTimeRef.current = remainingTimeRef.current - 1;
      syncRemainingTime();
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [startTimer]);

  const reset = () => {
    remainingTimeRef.current = secondsRemaining;
    syncRemainingTime();
    setStartTimer(true);
  };

  const _onResend = () => {
    onResend();
    reset();
  };

  return (
    <Button
      variant='link'
      textTransform='uppercase'
      color='blue.500'
      fontWeight='bold'
      fontSize='xs'
      cursor='pointer'
      isDisabled={remainingTimeRef.current > 0}
      onClick={_onResend}
      {...rest}>
      {remainingTimeRef.current > 0 && `Resend code ${isLoading ? '00:00' : formatSeconds(remainingTimeRef.current)}`}
      {remainingTimeRef.current <= 0 && 'Resend code'}
    </Button>
  );
};
