import { BasePagination } from '@elkaso-app/web-design';
import { useGetKasoWarehousesApi } from 'apis/warehouses/use-get-kaso-warehouses-api';
import { FilterOptions } from 'components/filter-options';
import { useSetPaginationParamsV2 } from 'hooks/use-set-pagination-params-v2';
import { Layout, Section } from 'layout';
import { ActionsCell, IWarehouseActionsCell } from 'pages/warehouses/components/actions-cell';
import React from 'react';

import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { NameFilterButton } from 'components/base-table/filters';
import { DownloadCSVButton } from 'components/download-csv-button';
import { UploadCSVButton } from 'components/upload-csv-button';
import { useVendorName } from 'hooks/use-vendor-name';
import { CellProps } from 'react-table';
import { getValidValue } from 'utils/get-valid-value';
import { ISelectAsyncWarehouseCell, SelectAsyncWarehouseCell } from './components/select-async-warehouse-cell';

export interface IWarehouse {
  kasoWarehouseId: number;
  kasoWarehouseName: string;
  posBranchName: string;
  posBranchId: string;
  reference: string;
}

export interface IKasoWarehouse {
  id: number;
  name: string;
  posMappedBranch: {
    branchId: number;
    vendorBranchId: string;
    name: string;
    reference: string | null;
  };
}

const WarehousesPage = () => {
  const setPaginationParams = useSetPaginationParamsV2();
  const { VendorName } = useVendorName();

  const {
    isLoading: isLoadingWarehouses,
    isFetching: isFetchingWarehouses,
    data: kasoWarehousesApiData,
  } = useGetKasoWarehousesApi();
  const { pagesCount, total, data: kasoWarehousesData } = kasoWarehousesApiData ?? {};

  const isLoading = isLoadingWarehouses;
  const isFetching = isFetchingWarehouses;

  const mergedWarehousesData = React.useMemo(() => {
    const warehouses: IWarehouse[] = [];

    kasoWarehousesData?.map((kasoWarehouse: IKasoWarehouse) => {
      const posWarehouse = kasoWarehouse.posMappedBranch || null;

      warehouses.push({
        kasoWarehouseId: kasoWarehouse?.id,
        kasoWarehouseName: kasoWarehouse?.name,
        posBranchName: getValidValue([posWarehouse?.name]),
        posBranchId: getValidValue([posWarehouse?.vendorBranchId]),
        reference: getValidValue([posWarehouse?.reference]),
      });
    });

    return warehouses;
  }, [kasoWarehousesApiData]);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Kaso Warehouse Name' filterButtonComponent={<NameFilterButton />} />,
        accessor: 'kaso_warehouse_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['kaso_warehouse_name']>) => (
          <TableCell value={value} fontWeight='semibold' fontSize='sm' color='red.500' />
        ),
      },
      {
        Header: <TableHeader name={`Select ${VendorName} Warehouse`} />,
        accessor: 'select_pos_warehouse',
        Cell: ({ row, value }: CellProps<any, IColumnsAccessorData['select_pos_warehouse']>) => (
          <CustomTableCell<ISelectAsyncWarehouseCell>
            as={SelectAsyncWarehouseCell}
            row={row}
            id={value?.kasoWarehouseId}
            defaultValue={{ id: value?.posBranchId, name: value?.posBranchName }}
          />
        ),
      },
      {
        Header: <TableHeader name={`${VendorName} Warehouse Name`} />,
        accessor: 'pos_warehouse_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['pos_warehouse_name']>) => (
          <TableCell value={value} fontWeight='semibold' fontSize='sm' color='blue.800' />
        ),
      },
      {
        Header: <TableHeader name='Reference' />,
        accessor: 'reference',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['reference']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ row, value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IWarehouseActionsCell> as={ActionsCell} row={row} warehouse={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    kaso_warehouse_name: string;
    select_pos_warehouse: IWarehouse;
    pos_warehouse_name: string;
    reference: string;
    actions: IWarehouse;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      mergedWarehousesData?.map((warehouse): IColumnsAccessorData => {
        return {
          id: warehouse?.kasoWarehouseId,
          kaso_warehouse_name: warehouse?.kasoWarehouseName || '--',
          select_pos_warehouse: warehouse,
          pos_warehouse_name: warehouse?.posBranchName || '--',
          reference: warehouse?.reference || '--',
          actions: warehouse,
        };
      }),
    [kasoWarehousesApiData]
  );

  return (
    <Layout type='sidebar_page_layout'>
      <Section name='content'>
        <BaseTableContainer isLoading={isLoading || isFetching}>
          <BaseTableContainer.Header>
            <BaseTableContainer.Header.Left>
              <BaseTableTitle title='Warehouses' />
            </BaseTableContainer.Header.Left>

            <BaseTableContainer.Header.Right>
              <DownloadCSVButton templateType='warehouses' disabled />
              <UploadCSVButton templateType='warehouses' disabled />
              <ClearFilterButton />
            </BaseTableContainer.Header.Right>
          </BaseTableContainer.Header>

          <BaseTableContainer.TableWrapper>
            <FilterOptions
              options={[
                { name: 'All', value: '' },
                { name: 'Mapped', value: 'true' },
                { name: 'Unmapped', value: 'false' },
              ]}
              paramName='mapped'
              radioInputName='mapped'
            />
            <BaseTable columns={columns} data={columnsAccessorData} />
          </BaseTableContainer.TableWrapper>

          <BaseTableContainer.Footer>
            <BaseTableContainer.Footer.Left />
            <BaseTableContainer.Footer.Right>
              <BasePagination>
                <BasePagination.Pages pagesCount={pagesCount} setSearchParams={setPaginationParams} />
                <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
              </BasePagination>
            </BaseTableContainer.Footer.Right>
          </BaseTableContainer.Footer>
        </BaseTableContainer>
      </Section>
    </Layout>
  );
};

export default WarehousesPage;
