import { Box, Center, Heading, SimpleGrid, Text, useRadio, useRadioGroup } from '@elkaso-app/web-design';
import { TVariables as AssignBrandVariables, useAssignBrandApi } from 'apis/vendors/use-assign-brand-api';
import { Layout, Section } from 'layout';
import { Navigate } from 'react-router';
import { useAppSelector } from 'redux/hooks';
import { URL } from 'utils/constants';

const RadioCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  return (
    <Box as='label'>
      <input {...getInputProps({})} hidden />
      <Center
        h='150px'
        bgColor='white'
        boxShadow='md'
        cursor='pointer'
        borderRadius='md'
        _checked={{
          shadow: 'lg',
          bgColor: 'red.500',
          color: 'white',
        }}
        {...getCheckboxProps()}>
        {props['aria-details']}
      </Center>
    </Box>
  );
};

const SelectBrandsPage = () => {
  const brands = useAppSelector((state) => state.listOfBrands.data);
  const { mutate: assignBrandApi } = useAssignBrandApi();

  const handleSelectChange = (vendorEntityId: string) => {
    const variables: AssignBrandVariables = {
      body: {
        vendorEntityId,
      },
    };

    assignBrandApi(variables);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue: '',
    onChange: handleSelectChange,
  });

  // Check if no brands stored
  if (!brands.length) return <Navigate to={URL.HOME} />;

  // Render
  return (
    <Layout type='pre_login_page_layout'>
      <Section name='content'>
        <Box w='100%' maxW='500px'>
          <Heading as='h3' mb='sm'>
            Brands
          </Heading>

          <Text fontSize='md' color='gray.500' mb='lg'>
            Please select from the below options
          </Text>

          <SimpleGrid minChildWidth='120px' spacing='md' {...getRootProps()}>
            {brands.map((brand: { _id: string; name: string }) => (
              <RadioCard key={brand._id} {...getRadioProps({ value: brand._id, 'aria-details': brand.name })} />
            ))}
          </SimpleGrid>
        </Box>
      </Section>
    </Layout>
  );
};

export default SelectBrandsPage;
