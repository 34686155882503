import { useCustomToast } from '@elkaso-app/web-design';
import { useGetKasoBranchesApi } from 'apis/branches/use-get-kaso-branches-api';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import http from 'apis/config/vendor-http-service';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'map-branch';
export const updateMapBranchEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

export type TVariables = {
  branchId: string;
  body: {
    vendorBranchId: string;
  };
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useUpdateMapBranchApi(options?: Options): ReturnType {
  const { refetch: refetchKasoBranches } = useGetKasoBranchesApi({ enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ branchId, body }: TVariables) => {
    try {
      const { data } = await http.patch(endpoint + `/${branchId}`, body);

      // re-fetch branches list
      refetchKasoBranches();

      toast({
        description: 'Branch have been mapped successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
