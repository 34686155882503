import {
  Box,
  Button,
  Center,
  FullPageSpinner,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Text,
  useRadio,
  useRadioGroup,
} from '@elkaso-app/web-design';
import { getMeEndpointIdentifier } from 'apis/use-me-api';
import { useFoodicsLoginApi } from 'apis/vendors/use-foodics-login-api';
import { useGetVendorsApi } from 'apis/vendors/use-get-vendors-api';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import { useRouteRedirectCorrection } from 'hooks/use-route-redirect-correction';
import { useVendorName } from 'hooks/use-vendor-name';
import { Layout, Section } from 'layout';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { LocalStorageKeys, URL } from 'utils/constants';
import { onLogout } from 'utils/on-logout';

const RadioCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  return (
    <Box as='label'>
      <input {...getInputProps({})} hidden />
      <Center
        h='150px'
        bgColor='white'
        boxShadow='md'
        cursor='pointer'
        borderRadius='md'
        _checked={{
          shadow: 'lg',
          bgColor: 'red.500',
          color: 'white',
        }}
        {...getCheckboxProps()}>
        <Image src={props?.['aria-details']} maxW='70%' />
      </Center>
    </Box>
  );
};

const SelectPOSPage = () => {
  // This is very important so we can force user got to the correct route.
  useRouteRedirectCorrection();
  const navigate = useNavigate();
  const me = useGetQueryClientData(getMeEndpointIdentifier);
  const { mutateAsync: foodicsLoginApi } = useFoodicsLoginApi();
  const { setVendorName } = useVendorName();

  const { data, isLoading } = useGetVendorsApi();

  const handleSelectChange = async (value: string) => {
    if (value === 'foodics') {
      setVendorName(value);

      // If this user have POS registered before
      if (me?.vendors?.length) {
        navigate(URL.CONFIRM_ACCOUNT);
      }

      // If this user have no POS registered before
      if (!me?.vendors?.length) {
        const data = await foodicsLoginApi({});

        if (data?.redirectUrl) {
          window.location.href = data?.redirectUrl;
        }

        if (!data?.redirectUrl) {
          localStorage.removeItem(LocalStorageKeys.vendor);
          localStorage.removeItem(LocalStorageKeys.access_token);
          window.location.pathname = URL.HOME;
        }

        return;
      }
    }
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue: '',
    onChange: handleSelectChange,
  });

  if (isLoading) return <FullPageSpinner />;
  return (
    <Layout type='pre_login_page_layout'>
      <Section name='content'>
        <Box w='100%' maxW='500px' position='relative'>
          <Button
            variant='ghost'
            leftIcon={<Icon as={MdArrowBack} />}
            pos='absolute'
            top='-150px'
            left='-40px'
            size='lg'
            colorScheme='transparent'
            color='black'
            onClick={onLogout}>
            Login
          </Button>

          <Heading as='h3' mb='sm'>
            POS
          </Heading>

          <Text fontSize='md' color='gray.500' mb='lg'>
            Please select from the below options
          </Text>

          <SimpleGrid minChildWidth='120px' spacing='md' {...getRootProps()}>
            {data?.map((value: any) => (
              <RadioCard key={value?.id} {...getRadioProps({ value: value?.slug, 'aria-details': value?.imageUrl })} />
            ))}
          </SimpleGrid>
        </Box>
      </Section>
    </Layout>
  );
};

export default SelectPOSPage;
