import { Box, Button, Divider, Heading, Text } from '@elkaso-app/web-design';
import { usePageParams } from 'hooks/use-page-params';
import { Layout, Section } from 'layout';
import { useEffect, useMemo, useState } from 'react';
import { LoginEmailForm } from './login-email-form';
import { LoginPhoneNumberForm } from './login-phone-number-form';

export type LoginType = 'email' | 'phoneNumber';

const LoginPage = () => {
  const [loginType, setLoginType] = useState<LoginType>('phoneNumber');

  const { getPageParams } = usePageParams();
  const params = getPageParams({ parseNumbers: false, decode: false });

  useEffect(() => {
    if (params?.loginType !== 'phoneNumber' && params?.loginType !== 'email') return;
    setLoginType(params?.loginType);
  }, [params?.loginType, params?.loginValue]);

  const isPhoneNumber = useMemo(() => {
    return loginType === 'phoneNumber';
  }, [loginType]);

  const isEmail = useMemo(() => {
    return loginType === 'email';
  }, [loginType]);

  const handleChangeLoginType = () => {
    if (isEmail) setLoginType('phoneNumber');
    else setLoginType('email');
  };

  return (
    <Layout type='pre_login_page_layout'>
      <Section name='content'>
        <Box w='100%' maxW='500px'>
          <Heading as='h3' mb='sm' color='blue.500'>
            Login
          </Heading>

          <Text fontSize='md' color='gray.500' mb='lg'>
            Please enter your account information
          </Text>

          <LoginPhoneNumberForm display={isPhoneNumber ? 'block' : 'none'} mt='8' />
          <LoginEmailForm display={isEmail ? 'block' : 'none'} mt='8' />

          <Divider mt='lg' mb='sm' />

          <Button onClick={handleChangeLoginType} size='lg' variant='' w='full' color='blue.500'>
            Continue with {isPhoneNumber ? 'email' : 'phone number'} instead
          </Button>
        </Box>
      </Section>
    </Layout>
  );
};

export default LoginPage;
