import { useCustomToast } from '@elkaso-app/web-design';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import http from 'apis/config/vendor-http-service';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetKasoWarehouseItemsApi } from './use-get-kaso-warehouse-items-api';

const endpoint = 'items';
export const unMapItemEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

export type TVariables = {
  itemId: number;
  subEntityId: number;
  subEntityType: string;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useUnmapWarehouseItemApi(options?: Options): ReturnType {
  const { refetch: refetchKasoWarehouseItems } = useGetKasoWarehouseItemsApi({ enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ itemId, subEntityId, subEntityType }: TVariables) => {
    try {
      const { data } = await http.delete(endpoint + `/${itemId}/${subEntityType}/${subEntityId}`, {
        baseURL: `${process.env.REACT_APP_BASE_URL}/pos/v2`,
      });

      // re-fetch warehouse items list
      refetchKasoWarehouseItems();

      toast({
        description: 'Item have been unmapped successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: 'Something went wrong! Try again later',
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
