import { useCustomToast } from '@elkaso-app/web-design';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import http from 'apis/config/vendor-http-service';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { onLogout } from 'utils/on-logout';

const endpoint = 'unlink';
export const unlinkEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type Options = Omit<UseMutationOptions<TData, TError, unknown, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, unknown>;

export function useUnlinkApi(options?: Options): ReturnType {
  const toast = useCustomToast();

  const mutationFn = async () => {
    try {
      const { data } = await http.post(endpoint);

      // Force user to logout after unlinking the account
      onLogout();

      return data;
    } catch (error: any) {
      onLogout();

      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: error.response.data.message,
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
