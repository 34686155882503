import { BasePagination } from '@elkaso-app/web-design';
import { FilterOptions } from 'components/filter-options';
import { useSetPaginationParamsV2 } from 'hooks/use-set-pagination-params-v2';
import { Layout, Section } from 'layout';
import { ActionsCell, IWarehouseItemsActionsCell } from 'pages/warehouse-items/components/actions-cell';
import {
  ISelectAsyncWarehouseItemsCell,
  SelectAsyncWarehouseItemsCell,
} from 'pages/warehouse-items/components/select-async-warehouse-items-cell';
import React from 'react';

import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { NameFilterButton } from 'components/base-table/filters';
import { DownloadCSVButton } from 'components/download-csv-button';
import { UploadCSVButton } from 'components/upload-csv-button';
import { useVendorName } from 'hooks/use-vendor-name';
import { CellProps } from 'react-table';
import { getValidValue } from 'utils/get-valid-value';
import { useGetKasoWarehouseItemsApi } from '../../apis/warehouse-items/use-get-kaso-warehouse-items-api';

export interface IWarehouseItem {
  id: number;
  kasoItemId: number;
  kasoSubEntityId: number;
  kasoSubEntityType: string;
  kasoSubEntityName: string;
  kasoItemName: string;
  kasoUnit: string;
  code: string;
  posItemName: string;
  posItemId: string;
  posUnit: string;
  sku: string;
}

interface IPosItem {
  id: number;
  name: string;
  sku: string;
  vendorItemId: string;
  measurementUnit: string;
}

interface IKasoItem {
  id: number;
  nameEn: string;
  nameAr: string;
  defaultUnit: string;
  defaultPrice: number;
  code: string;
  subEntity: {
    id: number;
    type: string;
    name: string;
  };
  vendor: {
    id: number;
    name: string;
  };
  posMappedItem: IPosItem;
}

interface IColumnsAccessorData {
  id: number;
  kaso_item_name: string;
  kaso_unit: string;
  code: string;
  select_pos_item: IWarehouseItem;
  actions: IWarehouseItem;
  pos_item_name: string;
  kaso_warehouse_name: string;
  pos_unit: string;
  sku: string;
}

const WarehouseItemsPage = () => {
  const setPaginationParams = useSetPaginationParamsV2();
  const { VendorName } = useVendorName();

  const {
    isLoading: isLoadingItems,
    isFetching: isFetchingItems,
    data: kasoItemsApiData,
  } = useGetKasoWarehouseItemsApi();
  const { pagesCount, total, data: kasoItemsData } = kasoItemsApiData ?? {};

  const isLoading = isLoadingItems;
  const isFetching = isFetchingItems;

  const mergedItemsData = React.useMemo(() => {
    const items: IWarehouseItem[] = [];

    kasoItemsData?.map((kasoItem: IKasoItem) => {
      const posItem = kasoItem?.posMappedItem || null;

      items.push({
        id: kasoItem?.id,
        kasoItemId: kasoItem?.id,
        kasoSubEntityId: kasoItem?.subEntity?.id,
        kasoSubEntityType: kasoItem?.subEntity?.type,
        kasoSubEntityName: kasoItem?.subEntity?.name,
        kasoItemName: getValidValue([kasoItem?.nameEn, kasoItem?.nameAr]),
        kasoUnit: kasoItem?.defaultUnit,
        code: kasoItem?.code,
        posItemName: getValidValue([posItem?.name]),
        posItemId: getValidValue([posItem?.vendorItemId]),
        posUnit: getValidValue([posItem?.measurementUnit]),
        sku: getValidValue([posItem?.sku]),
      });
    });

    return items;
  }, [kasoItemsApiData]);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name={'Kaso Item Name'} filterButtonComponent={<NameFilterButton />} />,
        accessor: 'kaso_item_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['kaso_item_name']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name={'Kaso Unit'} />,
        accessor: 'kaso_unit',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['kaso_unit']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name='Code' />,
        accessor: 'code',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['code']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name={`Select ${VendorName} Item`} />,
        accessor: 'select_pos_item',
        Cell: ({ row, value }: CellProps<any, IColumnsAccessorData['select_pos_item']>) => (
          <CustomTableCell<ISelectAsyncWarehouseItemsCell>
            as={SelectAsyncWarehouseItemsCell}
            row={row}
            id={value?.kasoItemId}
            defaultValue={{ id: value?.posItemId, name: value?.posItemName }}
          />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ row, value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IWarehouseItemsActionsCell> as={ActionsCell} row={row} item={value} />
        ),
      },
      {
        Header: <TableHeader name={`${VendorName} Item Name`} />,
        accessor: 'pos_item_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['pos_item_name']>) => (
          <TableCell value={value} fontWeight='semibold' fontSize='sm' color='blue.800' />
        ),
      },
      {
        Header: <TableHeader name='Warehouse' />,
        accessor: 'kaso_warehouse_name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['kaso_warehouse_name']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name={`${VendorName} Unit`} />,
        accessor: 'pos_unit',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['pos_unit']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
      {
        Header: <TableHeader name='SKU' />,
        accessor: 'sku',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['sku']>) => (
          <TableCell value={value} fontSize='sm' color='gray.500' />
        ),
      },
    ],
    []
  );

  const columnsAccessorData = React.useMemo(
    () =>
      mergedItemsData?.map((item): IColumnsAccessorData => {
        return {
          id: item?.kasoItemId,
          kaso_item_name: item?.kasoItemName || '--',
          kaso_unit: item?.kasoUnit || '--',
          code: item?.code || '--',
          select_pos_item: item,
          actions: item,
          pos_item_name: item?.posItemName || '--',
          kaso_warehouse_name: item?.kasoSubEntityName || '--',
          pos_unit: item?.posUnit || '--',
          sku: item?.sku || '--',
        };
      }),
    [kasoItemsApiData]
  );

  return (
    <Layout type='sidebar_page_layout'>
      <Section name='content'>
        <BaseTableContainer isLoading={isLoading || isFetching}>
          <BaseTableContainer.Header>
            <BaseTableContainer.Header.Left>
              <BaseTableTitle title='Warehouse Items' />
            </BaseTableContainer.Header.Left>

            <BaseTableContainer.Header.Right>
              <DownloadCSVButton templateType='warehouse_items' disabled />
              <UploadCSVButton templateType='warehouse_items' disabled />
              <ClearFilterButton />
            </BaseTableContainer.Header.Right>
          </BaseTableContainer.Header>

          <BaseTableContainer.TableWrapper>
            <FilterOptions
              options={[
                { name: 'All', value: '' },
                { name: 'Mapped', value: 'true' },
                { name: 'Unmapped', value: 'false' },
              ]}
              paramName='mapped'
              radioInputName='mapped'
            />
            <BaseTable columns={columns} data={columnsAccessorData} />
          </BaseTableContainer.TableWrapper>

          <BaseTableContainer.Footer>
            <BaseTableContainer.Footer.Left />
            <BaseTableContainer.Footer.Right>
              <BasePagination>
                <BasePagination.Pages pagesCount={pagesCount} setSearchParams={setPaginationParams} />
                <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
              </BasePagination>
            </BaseTableContainer.Footer.Right>
          </BaseTableContainer.Footer>
        </BaseTableContainer>
      </Section>
    </Layout>
  );
};

export default WarehouseItemsPage;
