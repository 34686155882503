import { Button, HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { TVariables as MapBranchVariables, useMapWarehouseApi } from 'apis/warehouses/use-map-warehouse-api';
import { TVariables as UnMapBranchVariables, useUnmapWarehouseApi } from 'apis/warehouses/use-unmap-warehouse-api';
import {
  TVariables as UpdateMapBranchVariables,
  useUpdateMapWarehouseApi,
} from 'apis/warehouses/use-update-map-warehouse-api';
import { MapIcon } from 'icons/map-icon';
import { UnMapIcon } from 'icons/unmap-icon';
import { IWarehouse } from '../index';

export interface IWarehouseActionsCell {
  row: Record<string, any>;
  warehouse: IWarehouse;
}

export const ActionsCell = ({ row, warehouse }: IWarehouseActionsCell) => {
  const { isLoading: isMapBranchLoading, mutate: mapBranchApi } = useMapWarehouseApi();
  const { isLoading: isUpdateMapBranchLoading, mutate: updateMapBranchApi } = useUpdateMapWarehouseApi();
  const { isLoading: isUnMapBranchLoading, mutate: unMapBranchApi } = useUnmapWarehouseApi();

  const handleMap = () => {
    // Map for first time
    if (!warehouse.posBranchId) {
      const variables: MapBranchVariables = {
        body: { branchId: row.state.warehouseId, vendorBranchId: row.state.vendorBranchId },
      };

      mapBranchApi(variables);
    }

    // Update map if mapped before
    if (warehouse.posBranchId) {
      const variables: UpdateMapBranchVariables = {
        branchId: row.state.warehouseId,
        body: {
          vendorBranchId: row.state.vendorBranchId,
        },
      };

      updateMapBranchApi(variables);
    }
  };

  const handleUnMap = () => {
    const variables: UnMapBranchVariables = {
      branchId: warehouse.kasoWarehouseId,
    };

    unMapBranchApi(variables);
  };

  return (
    <HStack spacing='xs'>
      <Button
        rightIcon={<MapIcon />}
        colorScheme='red'
        variant='solid'
        size='sm'
        px='md'
        isDisabled={!row.state.warehouseId}
        isLoading={isMapBranchLoading || isUpdateMapBranchLoading}
        onClick={handleMap}>
        Map
      </Button>

      <IconButtonWithTooltip
        label='Un-map'
        aria-label='Un-map Icon'
        colorScheme='blue'
        Icon={<UnMapIcon />}
        size='sm'
        isDisabled={!warehouse.posBranchId}
        isLoading={isUnMapBranchLoading}
        onClick={handleUnMap}
      />
    </HStack>
  );
};
