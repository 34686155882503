import { Flex, Heading, Text } from '@elkaso-app/web-design';
import React from 'react';

const NotFound: React.FC = () => {
  return (
    <Flex height='100vh' alignItems='center' justifyContent='center'>
      <Flex direction='column' textAlign='center'>
        <Heading size='4xl' mb={4}>
          404
        </Heading>
        <Text fontSize='xl' mb={8}>
          Oops! Page not found.
        </Text>
        <Text fontSize='md'>
          The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
        </Text>
      </Flex>
    </Flex>
  );
};

export default NotFound;
