import { LocalStorageKeys } from 'utils/constants';

export const handleRetryAttempts = () => {
  const getRetryAttempts = () => {
    const counter = localStorage.getItem(LocalStorageKeys.retry_attempts) ?? '1';
    return Number(counter);
  };

  const increaseRetryAttempts = () => {
    const counter = localStorage.getItem(LocalStorageKeys.retry_attempts);
    const newCounter = counter ? Number(counter) + 1 : 1;

    return localStorage.setItem(LocalStorageKeys.retry_attempts, newCounter.toString());
  };

  const resetRetryAttempts = () => {
    return localStorage.removeItem(LocalStorageKeys.retry_attempts);
  };

  return {
    getRetryAttempts,
    increaseRetryAttempts,
    resetRetryAttempts,
  };
};
