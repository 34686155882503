import { Box, BoxProps, Button, FormControl, FormErrorMessage, Input } from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import * as yup from 'yup';
import { useGetDefaultLoginValue } from './hooks/use-get-default-login-value';
import { useSignInCheck } from './hooks/use-sign-in-check';

type FormValues = {
  phoneNumber: string;
};

const schema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .notOneOf(
      ['+971', '+966', '+973', '+98', '+964', '+962', '+965', '+961', '+968', '+970', '+974', '+963', '+967', '+32'],
      'Phone number is required'
    )
    .matches(/^\+[1-9]{1}[0-9]{3,14}$/, 'Please enter a valid phone number')
    .test('Check country code', 'Country code is not acceptable', function (): any {
      const phoneNumber = this.parent['phoneNumber'];
      if (!phoneNumber) return;

      if (
        phoneNumber.startsWith('+971') ||
        phoneNumber.startsWith('+966') ||
        phoneNumber.startsWith('+973') ||
        phoneNumber.startsWith('+98') ||
        phoneNumber.startsWith('+964') ||
        phoneNumber.startsWith('+962') ||
        phoneNumber.startsWith('+965') ||
        phoneNumber.startsWith('+961') ||
        phoneNumber.startsWith('+968') ||
        phoneNumber.startsWith('+970') ||
        phoneNumber.startsWith('+974') ||
        phoneNumber.startsWith('+963') ||
        phoneNumber.startsWith('+967') ||
        phoneNumber.startsWith('+32')
      ) {
        return true;
      }

      return false;
    }),
});

export const LoginPhoneNumberForm = (props: BoxProps) => {
  const { isLoading: isLoadingSignInCheck, onSignInCheck } = useSignInCheck();
  const { phoneNumber } = useGetDefaultLoginValue();

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      phoneNumber,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ phoneNumber }) => {
    onSignInCheck('phoneNumber', phoneNumber);
  };

  return (
    <Box {...props}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          control={control}
          name='phoneNumber'
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error, invalid } }) => (
            <FormControl isRequired isInvalid={invalid}>
              <PhoneInput
                international
                placeholder='Please enter phone number'
                defaultCountry='AE'
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                ref={ref}
                size='lg'
                px='md'
                ml='sm'
                inputComponent={Input}
              />

              <FormErrorMessage>{error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Button type='submit' colorScheme='red' w='full' size='lg' mt='lg' isLoading={isLoadingSignInCheck}>
          Continue
        </Button>
      </form>
    </Box>
  );
};
