import { Icon } from '@elkaso-app/web-design';
import { BranchesIcon } from 'layout/components/sidebar/icons/branches-icon';
import { ItemsIcon } from 'layout/components/sidebar/icons/items-icon';
import { NotificationsIcon } from 'layout/components/sidebar/icons/notifications-icon';
import { SuppliersIcon } from 'layout/components/sidebar/icons/suppliers-icon';
import { URL } from 'utils/constants';

type SidebarChildItemType = {
  name: string;
  path: string;
  disable?: boolean;
};

export type SidebarItemType = {
  name: string;
  path: string;
  icon: typeof Icon;
  disable?: boolean;
  children?: SidebarChildItemType[];
};

export const sidebarItems: SidebarItemType[] = [
  {
    name: 'Branches',
    path: URL.BRANCHES,
    icon: BranchesIcon,
  },
  {
    name: 'Suppliers',
    path: URL.SUPPLIERS,
    icon: SuppliersIcon,
  },
  {
    name: 'Warehouses',
    path: URL.WAREHOUSES,
    icon: BranchesIcon,
  },
  {
    name: 'Items',
    path: URL.ITEMS,
    icon: ItemsIcon,
  },
  {
    name: "Warehouse's Items",
    path: URL.WAREHOUSE_ITEMS,
    icon: ItemsIcon,
  },
  {
    name: 'Notifications',
    path: URL.NOTIFICATIONS,
    icon: NotificationsIcon,
    disable: true,
  },
];
