import { useCustomToast } from '@elkaso-app/web-design';
import { useGetKasoBranchesApi } from 'apis/branches/use-get-kaso-branches-api';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import http from 'apis/config/vendor-http-service';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'map-branch/csv/mapping';
export const mapBranchesEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

export type TVariables = {
  body: FormData;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useMapBranchesApi(options?: Options): ReturnType {
  const { refetch: refetchKasoBranches } = useGetKasoBranchesApi({ enabled: false });
  const toast = useCustomToast();

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(endpoint, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // re-fetch branches list
      refetchKasoBranches();

      toast({
        description: 'Branches have been mapped successfully',
        status: 'success',
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      toast({
        description: 'Something went wrong! Try again later',
        status: 'error',
      });

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
