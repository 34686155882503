import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { LocalStorageKeys } from 'utils/constants';
import { onAuthLogout } from 'utils/on-auth-logout';

export const getPublicToken = () => localStorage.getItem(LocalStorageKeys.public_token) || '';
export const getLanguage = () => localStorage.getItem(LocalStorageKeys.language) || 'en';

// Create API instance
const auth = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/`,
});

auth.defaults.headers.post['Content-Type'] = 'application/json';

// Add a request interceptor
auth.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = {
      ['x-app-version']: 78,
      ['x-device-platform']: 'web',
      ['x-app-auth']: getPublicToken(),
      ['Accept-Language']: getLanguage(),
      ...config.headers,
    };

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
auth.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === 403) {
      // Handle 403 error here
    }

    if (error.response?.status === 401) {
      onAuthLogout();
    }

    return Promise.reject(error);
  }
);

export default auth;
