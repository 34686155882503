import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

/**
 * This class handles setting, checking, and handling session expiration in sessionStorage.
 *
 * @class
 * @classdesc Provides methods to manage sessions using sessionStorage.
 * @constructor Initializes default values of `sessionKey`, and `sessionExpiredCallback`.
 * @param {string} sessionKey - The key for the session item to be stored in sessionStorage.
 * @param {() => void} sessionExpiredCallback - The callback function to be executed when the session expires.
 * @public `setSession` - This method sets the session.
 * @public `checkSession` - This method checks the session.
 * @public `remainingTime` - This method returns the remaining time.
 * @public `isSessionExpired` - This method checks if the session has expired.
 * @private `sessionExpired` - This method is called when the session has expired.
 * @example
 * // Usage:
 * const sessionHandler = new SessionHandler('mySession', () => {
 *     console.log('Do something when the session expires...');
 * });
 *
 * sessionHandler.setSession(50);
 * sessionHandler.checkSession();
 */
export class SessionHandler {
  sessionKey: string;
  sessionDuration: number;
  sessionExpiredCallback?: () => void;

  constructor(sessionKey: string, sessionExpiredCallback?: () => void) {
    this.sessionKey = sessionKey;
    this.sessionDuration = 0;

    if (sessionExpiredCallback) {
      this.sessionExpiredCallback = sessionExpiredCallback;
    }
  }

  /**
   * This method sets the session.
   *
   * @param {number} sessionDuration - The duration for the session in seconds.
   */
  public setSession(sessionDuration: number): void {
    const currentTime = new Date().getTime();
    const expireTime = currentTime + sessionDuration * 1000; // Convert seconds to milliseconds

    window.sessionStorage.setItem(this.sessionKey, expireTime.toString());

    this.sessionDuration = sessionDuration;
  }

  /**
   * @method `checkSession` This method checks the session at regular intervals to see if it has expired.
   * If the session has expired, it removes the session from sessionStorage, stops checking, and calls sessionExpiredCallback.
   */
  public checkSession(): void {
    const intervalId = setInterval(() => {
      const expireTime = window.sessionStorage.getItem(this.sessionKey);

      if (expireTime) {
        if (new Date().getTime() > Number(expireTime)) {
          window.sessionStorage.removeItem(this.sessionKey);
          clearInterval(intervalId);

          this.sessionExpired();
        }
      } else {
        clearInterval(intervalId);
      }
    }, 1000); // Check every second
  }

  /**
   * This method returns the remaining time.
   *
   * @returns {number} - Returns a number of seconds of the remaining time.
   */
  public remainingTime(): number {
    const endTimeInMilliseconds = window.sessionStorage.getItem(this.sessionKey);
    if (!endTimeInMilliseconds) return 0;

    const now = dayjs();
    const endTimeInSeconds = Number(endTimeInMilliseconds) / 1000;
    const endTime = dayjs.unix(endTimeInSeconds);
    const remaining = endTime.diff(now, 'seconds');

    return remaining;
  }

  /**
   * This method checks if the session has expired.
   *
   * @returns {boolean} - Returns true if the session has expired, false otherwise.
   */
  public isSessionExpired(): boolean {
    const expireTime = window.sessionStorage.getItem(this.sessionKey);

    if (expireTime) {
      if (dayjs().valueOf() > Number(expireTime)) {
        window.sessionStorage.removeItem(this.sessionKey);
        return true;
      }
    }

    return false;
  }

  /**
   * This method is called when the session has expired.
   */
  private sessionExpired(): void {
    this.sessionExpiredCallback?.();
  }
}
