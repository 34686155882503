import { useIsLoggedIn } from 'hooks/use-is-logged-in';
import { Navigate } from 'react-router-dom';
import { URL } from 'utils/constants';

interface IProtectedRoute {
  component: JSX.Element;
}

export const ProtectedRoute = ({ component }: IProtectedRoute) => {
  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn) {
    return <Navigate to={URL.HOME} />;
  }

  return component;
};
