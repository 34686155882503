import { usePageParams } from 'hooks/use-page-params';
import { useMemo } from 'react';

export const useGetDefaultLoginValue = () => {
  const { getPageParams } = usePageParams();
  const { loginValue, loginType } = getPageParams({ parseNumbers: false, decode: false });

  const defaultLoginValue = useMemo(() => {
    return loginValue && typeof loginValue === 'string' ? loginValue : '';
  }, [loginValue]);

  return {
    phoneNumber: loginType === 'phoneNumber' ? defaultLoginValue : '',
    email: loginType === 'email' ? defaultLoginValue : '',
  };
};
