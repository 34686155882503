export enum LocalStorageKeys {
  access_token = 'access-token',
  refresh_token = 'refresh-token',
  public_token = 'public-token',
  has_mapped_branch = 'hasMappedBranch',
  vendor = 'vendor',
  language = 'language',
  country = 'country',
  retry_attempts = 'retry-attempts',
  is_support_login = 'is-support-login',
}

export enum SessionStorageKeys {
  otp_expiration_time = 'otp-expiration-time',
}

export const LANGUAGES = {
  en: {
    label: 'English',
    code: 'en',
  },
  ar: {
    label: 'العربية',
    code: 'ar',
  },
};

export enum URL {
  // General
  HOME = '/',
  NOT_FOUND = '/404',
  ACCESS_DENIED = '/access-denied',
  EXPIRED_SESSION = '/session-expired',

  // Pre Login
  VERIFY_OTP = '/verify-otp',
  LOGIN = '/login',
  SELECT_POS = '/select-pos',
  SELECT_BRANDS = '/select-brands',
  CONFIRM_ACCOUNT = '/confirm-account',

  // App
  APP = '/app',
  BRANCHES = '/app/branches',
  WAREHOUSES = '/app/warehouses',
  SUPPLIERS = '/app/suppliers',
  ITEMS = '/app/items',
  WAREHOUSE_ITEMS = '/app/warehouse_items',
  NOTIFICATIONS = '/app/notifications',
}
