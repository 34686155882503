import { Box, BoxProps, Button, FormControl, FormErrorMessage, Input } from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useGetDefaultLoginValue } from './hooks/use-get-default-login-value';
import { useSignInCheck } from './hooks/use-sign-in-check';

type FormValues = {
  email: string;
};

const schema = yup.object().shape({
  email: yup.string().required('Email address is required').email('Please enter a valid email address'),
});

export const LoginEmailForm = (props: BoxProps) => {
  const { isLoading: isLoadingSignInCheck, onSignInCheck } = useSignInCheck();
  const { email } = useGetDefaultLoginValue();

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      email,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ email }) => {
    onSignInCheck('email', email);
  };

  return (
    <Box {...props}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          control={control}
          name='email'
          render={({ field: { name, value, onChange, onBlur }, fieldState: { error, invalid } }) => (
            <FormControl isRequired isInvalid={invalid}>
              <Input
                type='email'
                size='lg'
                placeholder='email@example.com'
                value={value}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                px='md'
              />

              <FormErrorMessage>{error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <Button type='submit' colorScheme='red' w='full' size='lg' mt='lg' isLoading={isLoadingSignInCheck}>
          Continue
        </Button>
      </form>
    </Box>
  );
};
