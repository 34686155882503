import {
  Button,
  ChakraSelect,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  useBoolean,
  useDisclosure,
} from '@elkaso-app/web-design';
import { BaseFilterButton } from 'components/base-table/filters';
import { usePageParams } from 'hooks/use-page-params';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useGetKasoSuppliersApi } from '../../../apis/suppliers/use-get-kaso-suppliers-api';

type TOption = {
  value: string;
  label: string;
};

type TInputs = {
  supplier: TOption | undefined;
};

const defaultValues: TInputs = {
  supplier: undefined,
};

export const SupplierIdFilterButton = () => {
  const [isActive, setIsActive] = useBoolean(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();
  const { isLoading, data: suppliersData = [] } = useGetKasoSuppliersApi();

  useEffect(() => {
    if (params.supplierId) {
      setIsActive.on();
    } else {
      setIsActive.off();
    }
  }, [params.supplierId]);

  const { control, handleSubmit, formState, reset, setValue } = useForm<TInputs>({
    defaultValues: { ...defaultValues },
  });

  // Update values
  const updateValues = () => {
    const selected = suppliersData?.data?.find((item: { id: number; name: string }) => {
      return item?.id === Number(params?.supplierId);
    });

    if (selected) {
      setValue('supplier', { label: selected.name, value: selected.id });
    } else {
      setValue('supplier', undefined);
    }
  };

  useEffect(() => {
    updateValues();
  }, [params.supplierId, suppliersData]);

  const onSubmit = (data: TInputs) => {
    params.supplierId = data.supplier?.value as string;

    setPageParams(params);
    onClose();
    reset(data);
  };

  const handleClose = () => {
    onClose();
    updateValues();
  };

  const handleDisableFilterButton = () => {
    return !Object.keys(formState.dirtyFields).length;
  };

  return (
    <BaseFilterButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      isActive={isActive}
      formComponent={
        <Stack as='form' spacing='lg' p='md' pt='lg' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='supplier'
            render={({ field: { onChange, name, onBlur, value } }) => (
              <FormControl>
                <FormLabel>Supplier</FormLabel>
                <ChakraSelect
                  id='supplier'
                  isLoading={isLoading}
                  options={suppliersData?.data?.map((item: { id: number; name: string }) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                />
              </FormControl>
            )}
          />

          <HStack justify='flex-end'>
            <Button variant='outline' colorScheme='red' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='red' type='submit' disabled={handleDisableFilterButton()}>
              Filter
            </Button>
          </HStack>
        </Stack>
      }
    />
  );
};
